export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const PASSWORD_FORGET = '/whoops-remind-me';
export const ENGAGEMENT_BUSINESS_TERMS = '/engagement/terms';
export const ENGAGEMENT_BUSINESS_VERIFICATION_PENDING = '/engagement/signup-pending';
export const ENGAGEMENT_RETURNER_PROFILES = '/engagement/profiles';
export const RETURNER_PROFILE = '/returner/profile';
export const RETURNER_EDIT_PROFILE = '/returner/profile/edit';
export const RETURNER_CODEWARS = '/returner/codewars';
export const STATUS_404 = '/404';
export const RETURNER_HOME_PAGE = '/returner/home-page';