import React from "react";
import MatButton from '@material-ui/core/Button';

function Button(props) {

    let { id, color, onClick, disabled, buttonText} = props;

    return (
        <MatButton id={id} color={color} onClick={onClick} variant="contained" disabled={disabled}>
            {buttonText}
        </MatButton>
    )
}

Button.defaultProps = {
    color: "primary",
    disabled: false   
}

export default Button;