import React from 'react';
import CodeWarsProgress from '../../components/CodeWars';
import { AuthUserContext, withAuthorisation } from '../../components/Session';

function CodeWarsStats() {

    return (
        <AuthUserContext.Consumer>
            {   
                authUser => {
                    return ( 
                        <CodeWarsProgress authUser={authUser} />
                    ); 
                }
            }
        </AuthUserContext.Consumer>
    );
}


const condition = authUser => !!authUser;

export default withAuthorisation(condition)(CodeWarsStats);