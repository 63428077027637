import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import Container from '@material-ui/core/Container';
import Button from '../Buttons/Button';
import { Paper, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import * as ROUTES from '../../constants/routes';
import EmailValidator from "email-validator";

const INITIAL_STATE = {
    email: '',
    passwordOne: '',
    passwordTwo: '',
    error: null,
};


class SignUpFormBase extends Component {

    state = {
        ...INITIAL_STATE
    };

    onSignUp = async () => {
        const { email, passwordOne } = this.state;

        try {
            const authUser = await this.props.firebase.doCreateUserWithEmailAndPassword(email, passwordOne)
            
            // Now update in firebase
            await this.props.firebase.createBusinessUser(authUser.user.uid, {
                emailAddress: email
            });

            this.props.firebase.addEmail({
                to: "james.heggs@techreturners.com",
                message: {
                    subject: `New User Created - ${email}`,
                    html: `<h2>New User Created</h2><p>A new user has signed up with ${email}.</p><p>NOTE: They have not yet accepted the terms</p>`
                }
            });
            
            this.setState({ ...INITIAL_STATE });

            this.setState({ error: null });
            this.props.history.push(ROUTES.ENGAGEMENT_BUSINESS_TERMS);
        }
        catch(error) {
            this.setState({error});
        }
    }

    onChange = (fieldName, event) => {
        this.setState({ [fieldName]: event.target.value });
    };

    render() {
        const {
            email,
            passwordOne,
            passwordTwo,
            error,
        } = this.state;

        const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email === '' ||
            EmailValidator.validate(email) === false ;

        return (
            <div style={{ backgroundColor: "#baa8ba", height: "100vh" }}>
                <Container maxWidth="sm" style={{ paddingTop: "50px" }}>
                    <Paper style={{ textAlign: "center" }}>
                        <Typography variant="h4">Sign Up</Typography>
                        <Grid container spacing={3} style={{ padding: "20px 20px" }} >
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="email"
                                    label="Email"
                                    value={email}
                                    fullWidth
                                    onChange={(event) => this.onChange("email", event)}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="passwordOne"
                                    label="Password"
                                    value={passwordOne}
                                    fullWidth
                                    type="password"
                                    onChange={(event) => this.onChange("passwordOne", event)}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="passwordTwo"
                                    label="Repeat Password"
                                    value={passwordTwo}
                                    fullWidth
                                    type="password"
                                    onChange={(event) => this.onChange("passwordTwo", event)}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button buttonText="Sign Up" onClick={this.onSignUp} disabled={isInvalid}  />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Already got your account? <Link href={ROUTES.SIGN_IN} color="primary">Sign in here</Link></Typography>
                            </Grid>
                        </Grid>
                        {error && <Typography variant="body1">{error.message}</Typography>}
                    </Paper>
                </Container>
            </div>
        );
    }
}

const SignUpForm = compose(
    withRouter,
    withFirebase,
)(SignUpFormBase);
 
export default SignUpForm;