import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { purple } from "@material-ui/core/colors";
import ShareIcon from "@material-ui/icons/Share";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { Grid, Typography } from "@material-ui/core";
import Google from './googley.png';
import Button from '../Buttons/Button';

const useStyles = makeStyles((theme) => ({
    root: {
    maxWidth: 345,
    backgroundColor: '#FEF9FF',
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
        }),
    },
    avatar: {
        backgroundColor: purple[500],
        width: "70px",
    },
    span: {
        color: "purple",
        fontWeight: "extra-bold",
    },
    link: {
        textDecoration: 'none',
        color: 'rgb(84, 84, 84)'

    }
    }));

    export default function JobCard() {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <Grid container justify="space-around">
                <Grid item align="start" xs={3}>
                    <Avatar
                    aria-label="job"
                    variant="rounded"
                    className={classes.avatar}
                    >
                    <Typography style={{color: 'white'}}>JOB<span role="img" aria-label="star" style={{ paddingLeft: 5}}>⭐️</span></Typography>
                    </Avatar>
                </Grid>
                <Grid item align="center" xs={6} style={{ paddingTop: 20}}> 
                    <Typography variant="h6">
                        <span style={{ fontWeight: 'bold'}}>ROLE:</span> <a href="www.google.com" className={classes.link}><span style={{ color: '#545454'}}>
                            Frontend Developer at Google</span></a>
                    </Typography>
                </Grid>
                <Grid item align="end" xs={3} > 
                <IconButton aria-label="settings">
                    <FavoriteBorderIcon style={{ color: 'purple'}}/>
                </IconButton>
                </Grid>
            </Grid>

        <CardContent>
            <Grid container justify="center">
            <Grid item xs={12} align="start">
                <Typography>
                <span className={classes.span}>Company:</span> Google 
                <span className={classes.span}> Salary:</span> £60,000 
                <span className={classes.span}> Start:</span> ASAP 
                <span className={classes.span}> Description:</span>  <a href="www.google.com" className={classes.link}>Looking for an
                experienced front end developer with a passion for technology...</a>
                </Typography>
            </Grid>
            </Grid>
        </CardContent>

        <CardMedia>
            <img className={classes.media} src={Google} alt="Google logo"/>
        </CardMedia>

        <CardActions>
        <Grid container justify="space-between">
            <Grid item xs={3} align="center">
                <IconButton aria-label="share">
                    <ShareIcon style={{color: 'purple'}}/>
                </IconButton>
            </Grid>
            <Grid item xd={3} align="center" style={{ marginRight: 13}}>
                    <Button buttonText={"APPLY"}/>
            </Grid>
        </Grid>
        </CardActions>
        </Card>
    );
    }
