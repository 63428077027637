import React, { useState, useEffect } from 'react';
import { AuthUserContext, withAuthorisation } from '../../components/Session';
import ReturnerProfile from '../../components/ReturnerProfile';
import AppBar from '../../components/AppBar';

function ReturnerProfilePage({firebase}) {

    const [user, setUser] = useState(null);

    useEffect(() => {
        firebase.user(firebase.auth.currentUser.uid)
        .then((user) => {
            setUser(user);
        });
    }, [firebase]);

    if(user) {
        return (
            <AuthUserContext.Consumer>
                {   authUser => {
                        return (
                            <div>
                                <AppBar authUser={authUser} />
                                <div style={{marginTop: "80px"}}>
                                    <ReturnerProfile returnerProfile={user.returnerProfile} editable={authUser.uid === user.userId} />
                                </div>
                            </div>
                        )
                    }
                }
            </AuthUserContext.Consumer>
        );
    }

    return <span />
}

const condition = authUser => !!authUser;

export default withAuthorisation(condition)(ReturnerProfilePage);