import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const withAuthorisation = condition => Component => {
    function WithAuthorisation(props) {

        useEffect(() => {
            // Listens for authentication state changes
            const listener = props.firebase.onAuthUserListener(
                authUser => {
                  if (!condition(authUser)) {
                    props.history.push(ROUTES.SIGN_IN);
                  }
                },
                () => props.history.push(ROUTES.SIGN_IN),
              );

            return () => {
                listener();
            }
        }, [props.history, props.firebase]);

        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    condition(authUser) ? <Component {...props} /> : null
                }
            </AuthUserContext.Consumer>
        );
    }

    return compose(
        withRouter,
        withFirebase,
    )(WithAuthorisation);
};

export default withAuthorisation;