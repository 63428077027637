import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Editor from 'react-medium-editor';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';


// load theme styles with webpack
require('medium-editor/dist/css/medium-editor.css');
require('medium-editor/dist/css/themes/default.css');


function WysiwygField({fieldName, fieldValue, onSave}) {

    const [editing, setEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(fieldValue);

    const useStyles = makeStyles((theme) => ({
        returnerInfoSection: {
            margin: "20px auto",
            width: "70%"
        },

        profileField: {
            padding: 5,
            "&:hover" : {
                backgroundColor: "#baa8ba",
                borderRadius: 3,
                // padding: 5,
                cursor: "pointer",
                color: "#FFF",
                fontWeight: "bold"
            },

            "&:hover svg": {
                display: "inline",
                cursor: "pointer",
                paddingTop: 10,
            }
        }, 

        editIcon: {
            display: "none",
            float: "right",
        },

        editor: {
            fontFamily: "Didact Gothic",
            minHeight: "100px",
            border: "2px solid",
            borderColor: "#8b249a",
            borderRadius: 10,
            paddingLeft: 10,
            paddingRight: 10,
            outline: "none"
        },

        saveButton: {
            marginBottom: 10,
            marginTop: 10
        }


    }));

    const classes = useStyles();

    const showEditor = () => {
        setEditing(true);
    };

    const handleFieldChange = (text, medium) => {
        setCurrentValue(text);
    }

    const saveChanges = () => {
        setEditing(false);
        
        if(onSave) {
            onSave(fieldName, currentValue);
        }
    }


    if( editing ) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Button className={classes.saveButton} variant="contained" color="primary" size="small" onClick={saveChanges}>Save</Button>
                </Grid>
                <Grid item xs={12}>
                    <Editor className={classes.editor} 
                            text={currentValue} 
                            onChange={handleFieldChange} 
                            options={
                                {
                                    toolbar: {
                                        buttons: ['bold', 'italic', 'underline', 'strikethrough', 'orderedlist', 'unorderedlist', 'pre', 'anchor']
                                    }
                                }
                            }
                    />
                </Grid>
            </Grid>
        );
    }
    else {
        return (
            <div className={classes.profileField}>
                <EditIcon color="primary" className={classes.editIcon} />
                <Typography paragraph  onClick={showEditor}>
                    <span dangerouslySetInnerHTML={{ __html: currentValue }} />
                </Typography>
            </div>
        )
    }
}

export default WysiwygField;

