import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MatAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withFirebase } from '../Firebase';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

 

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuBarHidden: {
        display: "none"
    },
    title: {
        flexGrow: 1,
    },
    menuBarLink: {
        textDecoration: "none",
        color: theme.palette.text.primary
    }
}));

function AppBar({firebase, authUser}) {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        setAnchorEl(null);
        firebase.doSignOut();
    }
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <MatAppBar position="fixed">
                <Toolbar>
                    <Typography variant="h6" className={classes.title} style={{color:"white"}}>
                        Tech Returners Hub - Welcome {authUser.firstName}
                    </Typography>
                    <IconButton color="inherit" aria-label="profile" onClick={handleProfileClick}>
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {(authUser && authUser.userRoles.includes("RETURNER")) &&
                            <Link to={ROUTES.RETURNER_CODEWARS} className={classes.menuBarLink}>
                                <MenuItem>
                                    <ListItemIcon><DeveloperModeIcon /></ListItemIcon>
                                    <ListItemText>Code Wars</ListItemText>
                                </MenuItem>
                            </Link>
                        }
                        {(authUser && authUser.userRoles.includes("RETURNER")) &&
                            <Link to={ROUTES.RETURNER_EDIT_PROFILE} className={classes.menuBarLink}>
                                <MenuItem>
                                        <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                                        <ListItemText>Edit Profile</ListItemText>
                                </MenuItem>
                            </Link>
                        }
                        <MenuItem onClick={handleSignOut}>
                            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                            <ListItemText>Sign Out</ListItemText>
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </MatAppBar>
        </div>
    )
}

export default withFirebase(AppBar);