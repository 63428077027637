import React, { useState, useEffect } from 'react';
import { AuthUserContext } from '../Session';
import { withAuthorisation } from '../Session';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import AppBar from '../AppBar';
import {
    Typography,
    Container,
    Grid,
    TextField,
    InputAdornment,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import Button from '../Buttons/Button';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as BUSINESS_STATUS from '../../constants/business_status';
import EmailValidator from "email-validator";
import moment from "moment";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';


const BusinessSignUp = (props) => {

    const [form, setState] = useState({
        firstName: '',
        lastName: '',
        businessName: '',
        companyEmailAddress: '',
        jobTitle: '', 
        contactNumber: '07',
        termsAgreed: false,
        linkedInProfile: '',
        paymentOption: '',
        hearAboutUs: ''
    });

    const [termsUrl, setTermsUrl] = useState("");

    useEffect(() => {
        props.firebase.getStorageDownloadUrl("/legal/TERMS_OF_BUSINESS_FOR_THE_INTRODUCTION_OF_TECH RETURNERS_CANDIDATES_2020_06_09.pdf").then((url)=> {
            setTermsUrl(url);
        });
    });

    const updateField = (fieldName, e) => {
        setState({
            ...form,
            [fieldName]: e.target.value
        });
    };

    const updateTerms = () => {
        setState({
            ...form,
            "termsAgreed": !form.termsAgreed
        });
    };

    const submitForm = async (authUser) => {
        // Send user to returner profiles
        // Update Cloud Firebase
        
        // Now update in firebase
        await props.firebase.updateUser(authUser.uid, {
            firstName: form.firstName,
            lastName: form.lastName,
            businessInfo: {
                businessName: form.businessName,
                companyEmailAddress: form.companyEmailAddress,
                jobTitle: form.jobTitle,
                linkedInProfile: form.linkedInProfile,
                termsAgreed: form.termsAgreed,
                termsAgreedTimestamp: moment().unix(),
                verificationStatus: BUSINESS_STATUS.VERIFICATION_STATUS_PENDING,
                paymentOption: form.paymentOption
            },
            contactNumber: form.contactNumber
        })

        // No need to await this one
        props.firebase.addEmail({
            to: process.env.REACT_APP_ACCOUNT_SIGNUP_EMAIL_NOTIFICATION.split(" "),
            message: {
                subject: `New business awaiting verification to the Hub - ${form.businessName}`,
                html: `<h2>New TR Hub Signup</h2><p>Great news team - <strong>${form.firstName} ${form.lastName}</strong> (${form.companyEmailAddress}) from <strong>${form.businessName}</strong> just signed up to the Tech Returners Hub</p><ul><li>${form.firstName} ${form.lastName}</li><li>Company Email: ${form.companyEmailAddress}</li><li>Business Name: ${form.businessName}</li><li>Job Title: ${form.jobTitle}</li><li>ContactNumber: ${form.contactNumber}</li><li>LinkedIn: ${form.linkedInProfile}</li><li>Payment Terms: ${form.paymentOption}</li></ul>`
            }
        });

        props.history.push(ROUTES.ENGAGEMENT_BUSINESS_VERIFICATION_PENDING);
    }

    const isValid = form.firstName !== "" && 
                    form.lastName !== "" &&
                    form.businessName !== "" &&
                    form.jobTitle !== "" &&
                    form.contactNumber !== "" &&
                    form.companyEmailAddress !== "" &&
                    EmailValidator.validate(form.companyEmailAddress) &&
                    form.termsAgreed === true &&
                    form.paymentOption !== "";

    return (
        <AuthUserContext.Consumer>
            {authUser => {
                 return ( 
                    <div>
                        <AppBar authUser={authUser} />
                        <div style={{ marginTop: "50px" }}>
                        
                            <Container maxWidth="sm" style={{ paddingTop: "30px" }}>
                                <Typography variant="h5">Account Signup</Typography>
                                <Grid container spacing={3} style={{ padding: "20px 20px" }} >
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            id="firstName"
                                            label="First Name"
                                            fullWidth
                                            value={form.firstName}
                                            variant="outlined"
                                            onChange={(event) => {updateField("firstName", event)}}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            id="lastName"
                                            label="Last Name"
                                            fullWidth
                                            value={form.lastName}
                                            variant="outlined"
                                            onChange={(event) => {updateField("lastName", event)}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            id="businessName"
                                            label="Business"
                                            fullWidth
                                            value={form.businessName}
                                            variant="outlined"
                                            onChange={(event) => {updateField("businessName", event)}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            id="jobTitle"
                                            label="Job Title"
                                            fullWidth
                                            value={form.jobTitle}
                                            variant="outlined"
                                            onChange={(event) => {updateField("jobTitle", event)}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            id="companyEmailAddress"
                                            label="Company Email"
                                            fullWidth
                                            value={form.companyEmailAddress}
                                            variant="outlined"
                                            onChange={(event) => {updateField("companyEmailAddress", event)}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            id="contactNumber"
                                            label="Mobile Number"
                                            fullWidth
                                            value={form.contactNumber}
                                            variant="outlined"
                                            onChange={(event) => {updateField("contactNumber", event)}}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PhoneAndroidIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="linkedInProfile"
                                            label="LinkedIn Profile"
                                            fullWidth
                                            value={form.linkedInProfile}
                                            variant="outlined"
                                            onChange={(event) => {updateField("linkedInProfile", event)}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="hearAboutUs"
                                            label="How did you hear about us?"
                                            fullWidth
                                            value={form.hearAboutUs}
                                            variant="outlined"
                                            onChange={(event) => {updateField("hearAboutUs", event)}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            We recognise that during the current situation we want to support the businesses in our community, therefore we have put together payment plans to support you and the future of our returners
                                        </Typography>
                                        <RadioGroup aria-label="payment Option" name="paymentOption" value={form.paymentOption} onChange={(event) => {updateField("paymentOption", event)}}>
                                            <FormControlLabel value="upfront" control={<Radio />} label="Single Payment - 30 day payment terms" />
                                            <FormControlLabel value="threePayments" control={<Radio />} label="3 monthly payments - 14 day payment terms" />
                                            <FormControlLabel value="sixPayments" control={<Radio />} label="6 monthly payments - 7 day payment terms" />
                                            <FormControlLabel value="other" control={<Radio />} label="Other, lets chat" />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={form.termsAgreed}
                                                    name="termsAgreed"
                                                    color="primary"
                                                    onChange={updateTerms}
                                                />
                                            }
                                            label={<Typography variant="h6">Check here to indicate that you have read and agree to the terms of the <a href={termsUrl} target="_blank" rel="noopener noreferrer">Tech Returners customer agreement</a></Typography>}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{textAlign: "center"}}>
                                        <Button disabled={!isValid} buttonText="Complete Sign Up" onClick={() => submitForm(authUser)} />
                                    </Grid>
                                </Grid>
                            </Container>
                        </div>
                    </div>
                 )
                 }
            }
        </AuthUserContext.Consumer>
    );
}

const condition = authUser => !!authUser;

const BusinessSignUpForm = compose(
    withRouter,
    withFirebase,
    withAuthorisation(condition),
)(BusinessSignUp);



export default BusinessSignUpForm;