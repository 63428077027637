import React, { useState, useEffect } from 'react';
import { withAuthorisation } from '../Session';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ReactPlayer from 'react-player'
import GitHubIcon from '@material-ui/icons/GitHub';
import EmailIcon from '@material-ui/icons/Email';
import DescriptionIcon from '@material-ui/icons/Description';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import ReturnerCVDialog from './ReturnerCVDialog';

function ReturnerProfilePage({returnerProfile, firebase }) {

    const [videoUrl, setVideoUrl] = useState("");
    const [cvDialogOpen, setCvDialogOpen] = React.useState(false);

    useEffect(() => {
        
        if(returnerProfile.profileVideoLink) {
            firebase.getStorageDownloadUrl(returnerProfile.profileVideoLink).then((url)=> {
                setVideoUrl(url);
            });
        }

        firebase.logEvent("view_item", {item_id: returnerProfile.userId, item_name: `${returnerProfile.profileName} ${returnerProfile.lastName}`}, "BUSINESS");        
    }, [firebase, returnerProfile]);

    const useStyles = makeStyles((theme) => ({
        returnerInfoSection: {
            margin: "20px auto",
            width: "70%"
        },

        saveButton: {
            marginBottom: 10,
            marginTop: 10
        },
    }));

    const classes = useStyles();

    return (
        <div className={classes.returnerInfoSection}>
            <Grid container spacing={1} justify="center">
                
                { returnerProfile.profileVideoLink && 
                    <Grid item xs={12} md={6} align="center" style={{marginBottom: 30}}>
                        <ReactPlayer url={videoUrl} controls width='100%' height='100%' />
                    </Grid>
                }

                <Grid container justify="center">
                    <Grid item align="center" xs={2}>
                        <Link href={returnerProfile.gitHubLink} target="_blank" rel="noreferrer">
                            <IconButton aria-label="view github account">
                                <GitHubIcon />
                            </IconButton>
                        </Link>
                    </Grid>
                    { returnerProfile.cvFileLink && 
                        <Grid item align="center" xs={2}>
                            <IconButton aria-label="CV" onClick={() => {setCvDialogOpen(true)}}>
                                <DescriptionIcon />
                            </IconButton>
                            {returnerProfile.cvFileLink &&
                                <ReturnerCVDialog open={cvDialogOpen} onClose={() => {setCvDialogOpen(false)}} cvLink={returnerProfile.cvFileLink} />
                            }
                        </Grid>
                    }
                    <Grid item align="center" xs={2}>
                        <Link href={`mailto:sponsorship@techreturners.com?subject=Returner hiring interest - ${returnerProfile.profileName}`} target="_blank" rel="noreferrer">
                            <IconButton aria-label="send email">
                                <EmailIcon />
                            </IconButton>
                        </Link>
                    </Grid>
                </Grid>
                
                <Grid item xs={12} >
                    <Typography variant="h6">Tell us about your experience?</Typography>
                    <Typography paragraph>
                        <span dangerouslySetInnerHTML={{ __html: returnerProfile.experience }} />
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Tell us about your technical skills?</Typography>
                    <Typography paragraph >
                        <span dangerouslySetInnerHTML={{ __html: returnerProfile.techSkills }} />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Transferable Skills</Typography>
                    <Typography paragraph >
                        <span dangerouslySetInnerHTML={{ __html: returnerProfile.transferableSkills }} />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Tell us why you are passionate about a career in tech?</Typography>
                    <Typography paragraph >
                        <span dangerouslySetInnerHTML={{ __html: returnerProfile.careerInTech }} />
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

const condition = authUser => !!authUser 

export default withAuthorisation(condition)(ReturnerProfilePage);