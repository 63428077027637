import React from 'react';
import { AuthUserContext, withAuthorisation } from '../../components/Session';
import AppBar from '../../components/AppBar';
// import Paper from "@material-ui/core/Paper";
// import TweetCard from "../TweetCard/TweetCard";
import { makeStyles } from "@material-ui/core/styles";
// import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import NewsFeed from '../../components/NewsFeed/NewsFeed';

function ReturnerHomePage() {
    
    const useStyles = makeStyles(() => ({
        homePageContainer: {
            backgroundColor: '#CCCC', 
            height: '100vh', 
            width: '100%', 
            marginLeft: "-10px", 
            marginRight: "-10px", 
            marginTop: "-30px", 
            padding: 30
        }
    }));

    const classes = useStyles();

    return (
        <AuthUserContext.Consumer>
            {
                authUser => {
                    return (
                        <div className={classes.homePageContainer}>
                            <AppBar authUser={authUser} />

                            <Grid container style={{ marginTop: "90px" }} justify="center">
                                    <Grid item xs={12} md={6} align="center">
                                        <Typography variant="h5">Home</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container justify="center" style={{ marginTop: "20px" }}>
                                    <Grid item xs={12} md={6} align="center">
                                        <NewsFeed />
                                    </Grid>
                                </Grid>

                        </div>
                    );
                }
            }
        </AuthUserContext.Consumer>
    );
}


const condition = authUser => !!authUser;

export default withAuthorisation(condition)(ReturnerHomePage);