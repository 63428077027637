import React, { useState, useEffect } from 'react';
import { AuthUserContext, withAuthorisation } from '../../components/Session';
import ReturnerProfileEdit from '../../components/ReturnerProfile/edit-profile';
import AppBar from '../../components/AppBar';

function ReturnerEditProfilePage({firebase}) {

    
    const [user, setUser] = useState(null);
    const [returnerProfile, setReturnerProfile] = useState(null);

    useEffect(() => {
        firebase.user(firebase.auth.currentUser.uid)
        .then((user) => {
            setUser(user);
            user.returnerProfile.profileName = user.firstName;
            setReturnerProfile(user.returnerProfile);
        });
    }, [firebase]);

    if(user) {
        return (
            <AuthUserContext.Consumer>
                {   authUser => {
                        return (
                            <div>
                                <AppBar authUser={authUser} />
                                <div style={{marginTop: "80px"}}>
                                    { (returnerProfile && authUser.uid === user.userId) && 
                                        <ReturnerProfileEdit returnerProfile={returnerProfile} />
                                    }
                                </div>
                            </div>
                        )
                    }
                }
            </AuthUserContext.Consumer>
        );
    }

    return <span />
}

const condition = authUser => !!authUser;

export default withAuthorisation(condition)(ReturnerEditProfilePage);