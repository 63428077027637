import React from 'react'
import JobCard from '../JobCard/JobCard';

const NewsFeed = () => {

    return (
        <>
            <JobCard />
        </>
    )
}

export default NewsFeed;