
import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';



function TextInputField({fieldName, fieldValue, onSave, valueIsLink = false}) {

    const [currentValue, setCurrentValue] = useState(fieldValue);
    const [editing, setEditing] = useState(false);

    const useStyles = makeStyles((theme) => ({

        profileField: {

            "&:hover" : {
                backgroundColor: "#baa8ba",
                borderRadius: 3,
                padding: 5,
                cursor: "pointer",
                color: "#FFF",
                fontWeight: "bold"
            },

            "&:hover svg": {
                display: "inline",
                cursor: "pointer",
                paddingTop: 10,
            }
        }, 

        editIcon: {
            display: "none",
            float: "right",
        },

        saveButton: {
            marginBottom: 10,
            marginTop: 10
        }


    }));

    const handleChange = (event) => {
        setCurrentValue(event.target.value);
    }

    const saveChanges = () => {
        setEditing(false);
        
        if(onSave) {
            onSave(fieldName, currentValue);
        }
    }

    const enableEditing = () => {
        setEditing(true);
    }

    const classes = useStyles();


    if(editing) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Button className={classes.saveButton} variant="contained" color="primary" size="small" onClick={saveChanges}>Save</Button>
                </Grid>
                <Grid item xs={12}>
                    <TextField value={currentValue} fullWidth onChange={handleChange} />
                </Grid>
            </Grid>
        );
    }
    else {
        return (
            <div className={classes.profileField}>
                <EditIcon color="primary" className={classes.editIcon} />
                {valueIsLink ?
                    
                    <Typography paragraph  onClick={enableEditing}>
                        <a href={currentValue} target="_blank" rel="noopener noreferrer">
                            <span dangerouslySetInnerHTML={{ __html: currentValue }} />
                        </a>
                    </Typography>
                    :
                    <Typography paragraph  onClick={enableEditing}>
                        <span dangerouslySetInnerHTML={{ __html: currentValue }} />
                    </Typography>
                }
            </div>
        )
    }

    
}

export default TextInputField;