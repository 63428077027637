import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import Container from '@material-ui/core/Container';
import Button from '../Buttons/Button';
import { Paper, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ErrorIcon from '@material-ui/icons/Error';
import LoginRouteIdentifier from '../App/LoginRouteIdentifer';
import Link from '@material-ui/core/Link';


const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE };
  }
 
  onSignIn = async () => {

    try {

      const { email, password } = this.state;

      const authUser = await this.props.firebase.doSignInWithEmailAndPassword(email, password);
      const user = await this.props.firebase.user(authUser.user.uid);

      
      if(!user) {
        console.error("Unable to correctly identify user");
        throw new Error(`Username or password seems to be incorrect`);
      }

      this.redirectUser(user);
    }
    catch(error) {
      this.setState({error});
    }
  }

  redirectUser = (user) => {

    const loginDestination = LoginRouteIdentifier.calculateUserLoginDestination(user);

    this.setState({ ...INITIAL_STATE });
    this.props.history.push(loginDestination);
  }
 
  onChange = (fieldName, event) => {
    this.setState({ [fieldName]: event.target.value });
  };
 
  render() {

    
    const { email, password, error } = this.state;
 
    const isInvalid = password === '' || email === '';
 
    return (
      <div style={{backgroundColor: "#baa8ba", height: "100vh"}}>
        <Container maxWidth="sm" style={{paddingTop: "50px"}}>
          <Paper style={{textAlign: "center"}}>
            <Typography variant="h4" style={{paddingTop: 25}}>Tech Returners Hub</Typography>

            <Grid container spacing={2} style={{padding: "20px 20px"}} >

              <Grid item xs={12}>
                <TextField
                  required
                  id="email"
                  label="Email"
                  value={email}
                  fullWidth
                  inputProps={{ "data-testid": "emailInputField" }}
                  onChange={(event) => this.onChange("email", event)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="password"
                  label="Password"
                  value={password}
                  fullWidth
                  inputProps={{ "data-testid": "passwordInputField" }}
                  type="password"
                  onChange={(event) => this.onChange("password", event)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button id="signInButton" inputProps={{ "data-testid": "signInButton" }} disabled={isInvalid} buttonText="Sign In" onClick={this.onSignIn}  />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Don't have an account with us? <Link href={ROUTES.SIGN_UP} color="primary">Sign up here</Link></Typography>
              </Grid>
              {error && <Grid item xs={12}><ErrorIcon /><Typography variant="body1">{error.message}</Typography></Grid>}
            </Grid>
          </Paper>
        </Container>
      </div>
    );
  }
}
 
const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);
 
export default SignInForm;