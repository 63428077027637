import * as firebase from 'firebase/app';
import * as ROLES from '../../constants/roles';

import 'firebase/auth';	
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

class Firebase {
    constructor() {
        firebase.initializeApp(config);

        this.auth = firebase.auth();
        this.db = firebase.firestore();
        this.storage = firebase.storage();
        this.analytics = firebase.analytics();
    }

    doCreateUserWithEmailAndPassword = (email, password) => {
        return this.auth.createUserWithEmailAndPassword(email, password);
    }

    doSignInWithEmailAndPassword = (email, password) => {
        return this.auth.signInWithEmailAndPassword(email, password);
    }

    doSignOut = () => {
        return this.auth.signOut();
    }

    doPasswordReset = email => {
        return this.auth.sendPasswordResetEmail(email);
    }

    doPasswordUpdate = password => {
        return this.auth.currentUser.updatePassword(password);
    }

    // *** Merge Auth and DB User API *** //

    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(async (authUser) => {
            if (authUser) {
                const user = await this.user(authUser.uid);
                // merge auth and db user
                authUser = {
                    uid: authUser.uid,
                    ...user,
                };

                this.analytics.setUserId(authUser.uid);

                next(authUser);
            } 
            else {
                fallback();
            }
    });

    /** Storage APIs */
    getStorageDownloadUrl = (filepath) => {
        const pathReference = this.storage.ref();
        return pathReference.child(filepath).getDownloadURL();
    }

    uploadReturnerVideo = (userId, file) => {
        const metaData = {
            customMetadata: {
                'userid': userId
            }
        };
        const fileName = file.name;
        const extension = fileName.split('.').pop();
        const storageFileName = `${userId}-raw.${extension}`;

        const storageRef = firebase.app().storage(process.env.REACT_APP_FIREBASE_RETURNER_VIDEO_BUCKET).ref();
        return storageRef.child(storageFileName).put(file, metaData);// returns an upload task which can be observed
    }

    uploadCVFile = (userId, file) => {
        const metaData = {
            customMetadata: {
                'userid': userId
            }
        };
        const fileName = file.name;
        const extension = fileName.split('.').pop();
        const storageFileName = `${userId}-cv.${extension}`;

        const storageRef = firebase.app().storage().ref();
        return storageRef.child(`yjit/returner_profiles/${storageFileName}`).put(file, metaData);// returns an upload task which can be observed
    }

    /*** Analytics APIs */
    logEvent = (eventName, eventData, userRole) => {
        let fireEvent = true;
        if(userRole) {
            const currentUser = JSON.parse(localStorage.getItem('authUser'));
            fireEvent = currentUser.userRoles.includes(userRole);
        }

        if(fireEvent) {
            this.analytics.logEvent(eventName, eventData);
        }
        
    }

    /*** User Database API ****/
    user = async (uid)  => {

        const userRef = this.db.collection("users").doc(uid);
        const userDoc = await userRef.get();

        if(userDoc.exists) {
            const user = userDoc.data();
            user.userId = userDoc.id;
            return user;
        }
    }

    createBusinessUser = async (uid, data) => {
        const fieldValue = firebase.firestore.FieldValue;
        data.lastUpdated = fieldValue.serverTimestamp();
        data.userRoles = [ ROLES.BUSINESS_ROLE ];

        return await this.db.collection("users").doc(uid).set(data);
    }

    updateUser = async (uid, data) => {
        const fieldValue = firebase.firestore.FieldValue;
        data.lastUpdated = fieldValue.serverTimestamp();

        const userRef = this.db.collection("users").doc(uid);

        return await userRef.update(data);
    }

    users = async () => {
        const collectionRef =  this.db.collection("users");
        const data = await collectionRef.get();
        return data;
    }

    returnerUsers = async () => {
        const returnerUsers =  await this.db.collection("users").where('userRoles', 'array-contains', 'RETURNER').get();
        const returners = [];
        returnerUsers.forEach(doc => {
            const user = doc.data();
            user.userId = doc.id;
            returners.push(user)
        });
        return returners;
    }

    /** Codewars database API */
    /*** User Database API ****/
    codeWarsHistory = async (codeWarsUsername)  => {

        const codeWarsRef = this.db.collection("codeWars").doc(codeWarsUsername);
        const codeWarsDoc = await codeWarsRef.get();

        if(codeWarsDoc.exists) {
            return codeWarsDoc.data();
        }
    }

    /** Mail Database */
    // Writing an entry to the firebase mail collection will
    // auto trigger the sending of an email
    addEmail = async (mail) => {
        return this.db.collection("mail").add(mail);
    }
}

export default Firebase;