import React from "react";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import BusinessTermsPage from '../BusinessTerms';
import BusinessSignupPending from '../BusinessTerms/SignupPending';
import ReturnerProfilesPage from '../../pages/engagement/returner-profiles';
import SignInPage from '../SignIn';
import SignUpPage from '../SignUp';
import PasswordForgetPage from '../PasswordForget'
import ReturnerProfilePage from '../../pages/returner/returner-profile';
import ReturnerProfileEditPage from '../../pages/returner/edit-profile';
import CodeWarsStatsPage from '../../pages/returner/code-wars';

import ReturnerHomePage from '../../pages/returner/home-page';

// Material themes and Tech Returners Theme Override
import { ThemeProvider } from '@material-ui/core/styles';
import TechReturnersTheme from '../../themes/tech-returners-theme';


function App() {

    return (
        <ThemeProvider theme={TechReturnersTheme}>
            <Router>
                <Route exact path={ROUTES.LANDING} component={SignInPage} />
                <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
                <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
                <Route path={ROUTES.ENGAGEMENT_RETURNER_PROFILES} component={ReturnerProfilesPage} />
                <Route path={ROUTES.ENGAGEMENT_BUSINESS_TERMS} component={BusinessTermsPage} />
                <Route path={ROUTES.ENGAGEMENT_BUSINESS_VERIFICATION_PENDING} component={BusinessSignupPending} />
                <Route path={ROUTES.RETURNER_PROFILE} exact component={ReturnerProfilePage} />
                <Route path={ROUTES.RETURNER_EDIT_PROFILE} exact component={ReturnerProfileEditPage} />
                <Route path={ROUTES.RETURNER_CODEWARS} exact component={CodeWarsStatsPage} />
                <Route path={ROUTES.RETURNER_HOME_PAGE} exact component={ReturnerHomePage} />
            </Router>
        </ThemeProvider>
    );
}

export default withAuthentication(App);