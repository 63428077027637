import React, { useState, useEffect } from "react";
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = Component => {

  function WithAuthentication(props) {

    const [authUser, setAuthUser] = useState(null);

    useEffect(() => {
      // Listens for authentication state changes
      const listener = props.firebase.onAuthUserListener(
        authUser => {
          localStorage.setItem('authUser', JSON.stringify(authUser));
          setAuthUser(authUser);
        },
        () => {
          localStorage.removeItem('authUser');
          setAuthUser(null);
        },
      );

      return () => {
        listener();
      }
    }, [props.firebase]);

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;