import React, { useState, useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { withFirebase } from '../Firebase';

import { Document, Page, pdfjs } from 'react-pdf';

import { Grid } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function ReturnerCVDialog({ open, onClose, cvLink, firebase }) {
    
    const [numPages, setNumPages] = useState(null);
    const [cvUrl, setCvUrl] = useState("");

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.4.456/pdf.worker.js`;

    useEffect(() => {
        
        if(open) {
            firebase.getStorageDownloadUrl(cvLink).then((url)=> {
                setCvUrl(url);
            });
        }
    }, [firebase, cvLink, open]);


    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const classes = useStyles();

    return (
        <Dialog fullWidth={true} maxWidth="md" open={open} onClose={onClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h5" className={classes.title} style={{color:"white"}}>
                        CV
                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid container justify="center">
                <Grid item xs={12} align="center">
                    { cvUrl && 
                        <Document file={cvUrl} onLoadSuccess={onDocumentLoadSuccess}>
                        {
                            Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                ),
                            )
                        }
                        </Document>
                    }
                </Grid>
            </Grid>
        </Dialog>
    );
}

export default withFirebase(ReturnerCVDialog);