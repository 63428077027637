import React, { useState, useEffect } from 'react';
import { withAuthorisation, AuthUserContext } from '../../components/Session';
import AppBar from '../../components/AppBar';
import ReturnerProfileCard from '../../components/ReturnerProfiles/returner-profile-card';
import returnerData from '../../components/ReturnerProfiles/profile-data.json';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import * as ROLES from '../../constants/roles';


function ReturnerProfiles({ firebase }) {

    // Hard coded array of returner ordering, // means still waiting for profile
    const order = [
        { userId: "CCILfS92iVQstN8o2BK2qBP9FzZ2"}, //Big Suze!
        { userId: "h1NYarZVPzMNlVWDuT9GbqSOrbS2"},//Saoirse
        { userId: "sMUofiTJRgWzcp88U5Ecj1WcKb72"},// Zainab 
        { userId: "j1p2bCVmtChOrogCTEC7BL0czFY2"},//James 
        { userId: "z57OafxhXMcFyMXai09L4ebpG4G2"},//Hannah B 
        { userId: "3kGUHH95upWJQyZZQmMXgUDyZ6H2"},//Jennifer 
        { userId: "VPngsk7hCEQ6T7teqkayc6wfhM92"},//Iliyan 
        {userId: "NON-DB-010-Mariya"},
        //KatieB",
        {userId: "W4IPm3hWbfThm1x4x906jffYdIA3"},//Matt 
        {userId: "aEIbfH6ynhY9YPJTia5kL3Uqq9C2"},//Holly 
        //Javier",
        {userId: "cbOpCqkQSsgTPZJO0SrZVg8E4ie2"}, //Nacho",
        {userId: "NON-DB-009-Helen"},
        {userId: "4nQPyDCjr1T3RX5LTCFt8fpeMwF2"},//Kate Santo 
        {userId: "NON-DB-005-Graham"},
        {userId: "jIOW9VfYFHOyTa6dM3fFNymbjjJ3"},//Fatima A 
        {userId: "SiBqPefQibfIoAoEGsgh0qp9OzA2"},
        {userId: "zczZWXIDbbd2Y4x4RrY4D1ZfYVc2"},//Ruzina 
        //DaveS
        //Ketaki
        {userId: "DtXWW1xIISdtAmv0FbUnbf35KH93"},//Kinga 
        {userId: "NON-DB-008-Nichole"},
        {userId: "gExsrWMnaIMq280Cw9XbFJuy8oo2"},//Shobir 
        //Susanna
        {userId: "NON-DB-002-KatieP"}, //KatieP
        {userId: "NON-DB-003-AnnB"}, //AnnB
        {userId: "NON-DB-004-Caio"}, //Caio
        {userId: "NON-DB-006-Lydia"}, //Lydia
    ];

    const [availabilityFilter, setAvailabilityFilter] = useState('');
    const [returners, setReturners] = useState([]);
  

    const handleAvailabilityFilterChange = (event) => {
        setAvailabilityFilter(event.target.value);
    };

    useEffect(() => {
        async function fetchUserData() {
            const returners = await firebase.returnerUsers();

            // Work around for static profiles that are listed in the profile-data.json file
            returners.forEach(returner => {
                if (returner.returnerProfile) {
                    const returnerProfile = returner.returnerProfile;
                    returnerProfile.profileName = returner.firstName;
                    returnerProfile.lastName = returner.lastName;
                    returnerProfile.userId = returner.userId;
                    returnerData.returners.push(returnerProfile);
                }
            })

            setReturners(returnerData.returners);     
        }
        fetchUserData()
    }, [firebase]);


    const ordered = [];
    const orderedAndFiltered = [];

    order.forEach(user => {
        if(returners !== []){
            const person = returners.filter((returner) => returner.userId === user.userId)[0];
            ordered.push(person);
        }
    })

    ordered.forEach((returner) => returner !== undefined ? orderedAndFiltered.push(returner) : '');

    return (
        <AuthUserContext.Consumer>
            {authUser => {
                return (
                    <div style={{ marginTop: "80px" }}>
                        <AppBar authUser={authUser} />
                        <Grid container spacing={3} justify="flex-end">
                            <Grid item xs={12} md={3}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="returner-availability-label">Availability</InputLabel>
                                    <Select labelId="returner-availability-label"
                                        id="returner-availability"
                                        value={availabilityFilter}
                                        onChange={handleAvailabilityFilterChange}
                                        label="Availability"
                                    >
                                        <MenuItem value="">Show All</MenuItem>
                                        <MenuItem value="available">Looking to return</MenuItem>
                                        <MenuItem value="returned">Returned to tech</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} >
                            {
                                orderedAndFiltered.map((returner, index) => {
                                    
                                        let visible = availabilityFilter === "" ||
                                        (availabilityFilter === "returned" && returner.employment) ||
                                        (availabilityFilter === "available" && !returner.employment);


                                    if (visible) {
                                        return (

                                            <Grid item xs={12} md={3} key={index}>
                                                <ReturnerProfileCard returnerProfile={returner} />
                                            </Grid>

                                        );
                                    }
                                    else {
                                        // Not really required only there to satisfy the default return
                                        return <span key={index} />
                                    }

                                    
                                })
                            }
                        </Grid>
                    </div>
                )

            }
            }
        </AuthUserContext.Consumer>
    );
}

const condition = authUser => !!authUser &&
    authUser.userRoles &&
    (
        authUser.userRoles.includes(ROLES.BUSINESS_ROLE) ||
        authUser.userRoles.includes(ROLES.TR_EMPLOYEE) ||
        authUser.userRoles.includes(ROLES.TR_EMPLOYEE_ADMIN)
    );

export default withAuthorisation(condition)(ReturnerProfiles);