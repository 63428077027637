import React from 'react';
import { withAuthorisation } from '../Session';
import {DropzoneDialog} from 'material-ui-dropzone'
import DescriptionIcon from '@material-ui/icons/Description';

function ReturnerCVUploadDialog({open, onClose, onUploadStarted, firebase}) {

    const handleSave = async (files) => {
        const [ cvFile ] = files;
        const uploadTask = firebase.uploadCVFile(firebase.auth.currentUser.uid, cvFile);
        onUploadStarted(uploadTask);
    }

    const getPreviewIcon = () => {
        return <DescriptionIcon />;
    }

    return <DropzoneDialog
                open={open}
                onSave={handleSave}
                acceptedFiles={['application/pdf' ]}
                showPreviews={true}
                dialogTitle="Upload a new CV"
                dropzoneText="Drag and drop a PDF file here or click"
                maxFileSize={500000000}
                filesLimit={1}
                getPreviewIcon={getPreviewIcon}
                onClose={onClose}
/>

}

const condition = authUser => !!authUser 

export default withAuthorisation(condition)(ReturnerCVUploadDialog);