import React from 'react';
import { withAuthorisation } from '../Session';
import {DropzoneDialog} from 'material-ui-dropzone'
import MovieIcon from '@material-ui/icons/Movie';

function ReturnerVideoUploadDialog({open, onClose, onUploadStarted, firebase}) {

    const handleSave = async (files) => {
        const [ videoFile ] = files;
        const uploadTask = firebase.uploadReturnerVideo(firebase.auth.currentUser.uid, videoFile);
        onUploadStarted(uploadTask);
    }

    const getPreviewIcon = () => {
        return <MovieIcon />;
    }

    return <DropzoneDialog
                open={open}
                onSave={handleSave}
                acceptedFiles={['video/mp4', 'video/quicktime', 'video/x-msvideo' ]}
                showPreviews={true}
                dialogTitle="Upload new profile video"
                dropzoneText="Drag and drop a video file here or click"
                maxFileSize={500000000}
                filesLimit={1}
                getPreviewIcon={getPreviewIcon}
                onClose={onClose}
/>

}

const condition = authUser => !!authUser 

export default withAuthorisation(condition)(ReturnerVideoUploadDialog);