import React, { useState, useEffect } from 'react';
import { AuthUserContext, withAuthorisation } from '../Session';
import { Line } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Container } from '@material-ui/core';
import AppBar from '../AppBar';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

function CodeWarsStats({ firebase, authUser }) {

    const materialTheme = useTheme();

    const [codeWarsData, setCodeWarsData] = useState(null);
    const [codeWarsProgress, setCodeWarsProgress] = useState({});
    const [lastUpdated, setLastUpdated] = useState("")

    useEffect(() => {

        async function fetchCodeWarsData() {

            const codeWarsData = await firebase.codeWarsHistory(authUser.codeWarsUsername);

            // // show last 8 results
            if (codeWarsData.progress && codeWarsData.progress.length > 8) {

                codeWarsData.progress = codeWarsData.progress.slice(codeWarsData.progress.length - 8, codeWarsData.progress.length);
            }

            setCodeWarsData(codeWarsData);
            setLastUpdated(moment.unix(codeWarsData.progress[codeWarsData.progress.length - 1].timestamp).format("DD MMM YYYY"));

            const labels = codeWarsData.progress.map((progressItem) => {
                return moment.unix(progressItem.timestamp).format('DD MMM YY');
            })

            const dataPoints = codeWarsData.progress.map((progressItem) => {
                return progressItem.honor;
            })

            // Convert to required format for Chart.js
            setCodeWarsProgress({
                labels: labels,
                datasets: [
                    {
                        label: 'CodeWars Honor',
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: materialTheme.palette.primary.main,
                        borderColor: materialTheme.palette.primary.main,
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: materialTheme.palette.primary.main,
                        pointBackgroundColor: materialTheme.palette.primary.main,
                        pointBorderWidth: 1,
                        pointHoverRadius: 10,
                        pointHoverBackgroundColor: materialTheme.palette.primary.main,
                        pointHoverBorderColor: '#FFF',
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                        data: dataPoints
                    }
                ],
            });
        }
        fetchCodeWarsData()

    }, [firebase, authUser, materialTheme.palette]);


    const useStyles = makeStyles((theme) => ({
        codeWarsStatsRow: {
            borderBottom: `2px solid ${theme.palette.secondary.main}`,
            padding: 10
        },
    }));

    const classes = useStyles();

    let languageData = <span />;
    if (codeWarsData && codeWarsData.ranks) {
        languageData = Object.keys(codeWarsData.ranks.languages).map((language, index) => {
            return <Typography variant="body1" key={index}>{language} - {codeWarsData.ranks.languages[language].score}</Typography>
        })
    }

    return (
        <AuthUserContext.Consumer>
            {
                authUser => {
                    return (
                        <div>
                            <AppBar authUser={authUser} />
                            <Container>
                                <Grid container style={{ marginTop: "90px" }} spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h5">Code Wars: Your progress!</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "30px" }} spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Grid container className={classes.codeWarsStatsRow}>
                                            <Grid item xs={8}>
                                                <Typography variant="body1">Last updated</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1">{lastUpdated}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.codeWarsStatsRow}>
                                            <Grid item xs={8}>
                                                <Typography variant="body1">Clan</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1">{codeWarsData ? codeWarsData.clan : "N/A"}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.codeWarsStatsRow}>
                                            <Grid item xs={8}>
                                                <Typography variant="body1">Challenges completed</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1">{codeWarsData ? codeWarsData.codeChallenges.totalCompleted : 0}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.codeWarsStatsRow}>
                                            <Grid item xs={8}>
                                                <Typography variant="body1">Overall Honor</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1">{codeWarsData ? codeWarsData.honor : 0}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.codeWarsStatsRow}>
                                            <Grid item xs={8}>
                                                <Typography variant="body1">Languages</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                {languageData}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Line data={codeWarsProgress} options={{
                                            scales: {
                                                yAxes: [{
                                                    ticks: {
                                                        callback: function (value, index, values) {
                                                            // when the floored value is the same as the value we have a whole number
                                                            if (Math.floor(value) === value) {
                                                                return value;
                                                            }
                                                        }
                                                    }
                                                }]
                                            }
                                        }}
                                        />
                                    </Grid>
                                </Grid>
                            </Container>
                        </div>
                    );
                }
            }
        </AuthUserContext.Consumer>
    );
}


const condition = authUser => !!authUser;

export default withAuthorisation(condition)(CodeWarsStats);