import React, { useState, useEffect } from 'react';
import { withAuthorisation } from '../Session';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ReactPlayer from 'react-player'
import WysiwigField from '../EditableFields/WysiwygField';
import TextInputField from '../EditableFields/TextInputField';
import ReturnerVideoUploadDialog from './ReturnerVideoUploadDialog';
import ReturnerCVUploadDialog from './ReturnerCVUploadDialog'
import LinearProgress from '@material-ui/core/LinearProgress';
import ReturnerCVDialog from '../ReturnerProfile/ReturnerCVDialog';


function ReturnerProfileEdit({returnerProfile, firebase}) {

    const [videoUrl, setVideoUrl] = useState("");
    const [editVideoVisible, setEditVideoVisible] = useState(false);
    const [editCVVisible, setEditCVVisible] = useState(false);
    const [uploadStatus, setUploadStatus] = useState({progress: 0, status: "NOT_STARTED"})
    const [cvDialogOpen, setCvDialogOpen] = React.useState(false);

    useEffect(() => {
        
        if(returnerProfile.profileVideoLink) {
            firebase.getStorageDownloadUrl(returnerProfile.profileVideoLink).then((url)=> {
                setVideoUrl(url);
            });
        }

        firebase.logEvent("view_item", {item_id: returnerProfile.userId, item_name: `${returnerProfile.profileName} ${returnerProfile.lastName}`}, "BUSINESS");        
    }, [firebase, returnerProfile]);

    const useStyles = makeStyles((theme) => ({
        returnerInfoSection: {
            margin: "20px auto",
            width: "70%"
        },

        saveButton: {
            marginBottom: 10,
            marginTop: 10
        },
    }));

    const saveProfileFieldChange = async (fieldName, fieldValue) => {

        returnerProfile[fieldName] = fieldValue;
        await firebase.updateUser(firebase.auth.currentUser.uid, { returnerProfile });
    }

    const csvUploadProgress = (uploadTask) => {
        setEditCVVisible(false);
        uploadTask.on('state_changed', function(){},
        function(error) {
            // Handle unsuccessful uploads
        }
        , function() {
            saveProfileFieldChange("cvFileLink", `/yjit/returner_profiles/${uploadTask.snapshot.metadata.name}`)
        });
    }

    const uploadProgress = (uploadTask) => {
        setEditVideoVisible(false);
        uploadTask.on('state_changed', function(snapshot){
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadStatus({progress, status: "IN_PROGRESS"});
        },
        function(error) {
            // Handle unsuccessful uploads
        }
        , function() {
            setUploadStatus({progress: 100, status: "COMPLETED"});
        });
    }

    const classes = useStyles();

    return (
        <div className={classes.returnerInfoSection}>
            <Grid container spacing={1} justify="center">
                
                <Grid item xs={12} md={6} align="center" style={{marginBottom: 50}}>
                    { returnerProfile.profileVideoLink && <ReactPlayer url={videoUrl} controls width='100%' height='100%' /> }

                    <Button className={classes.saveButton} variant="contained" color="primary" size="small" onClick={()=> setEditVideoVisible(true)}>Update Video</Button>
                    <ReturnerVideoUploadDialog open={editVideoVisible} onClose={()=> setEditVideoVisible(false)} onUploadStarted={uploadProgress} />
                </Grid>

                { uploadStatus.status !== "NOT_STARTED" &&
                    <Grid container justify="center">
                        <Grid item xs={12} md={6} align="center" style={{marginBottom: 50}}>
                            { uploadStatus.status === "IN_PROGRESS" &&
                                <LinearProgress variant="determinate" value={uploadStatus.progress} />
                            }
                            { uploadStatus.status === "COMPLETED" &&
                                <Typography variant="body1">Your video is now being processed.<br />It should take around 10 mins if you want to refresh the screen shortly.</Typography>
                            }
                        </Grid>
                    </Grid>
                }

                <Grid item xs={12}>
                    <Typography variant="h6">CV</Typography>
                    <Grid container>
                        { returnerProfile.cvFileLink && 
                                <Grid item xs={1}>
                                    <Button className={classes.saveButton} variant="contained" color="primary" size="small" onClick={() => {setCvDialogOpen(true)}}>View CV</Button>
                                    <ReturnerCVDialog open={cvDialogOpen} onClose={() => {setCvDialogOpen(false)}} cvLink={returnerProfile.cvFileLink} />
                                </Grid>
                        }
                        <Grid item xs={1}>
                            <Button className={classes.saveButton} variant="contained" color="primary" size="small" onClick={()=> setEditCVVisible(true)}>Update CV</Button>
                            <ReturnerCVUploadDialog open={editCVVisible} onClose={()=> setEditCVVisible(false)} onUploadStarted={csvUploadProgress} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">GitHub</Typography>
                    <TextInputField fieldName="gitHubLink" fieldValue={returnerProfile.gitHubLink} valueIsLink onSave={saveProfileFieldChange} />
                </Grid>
                
                <Grid item xs={12} >
                    <Typography variant="h6">Tell us about your experience?</Typography>
                    <WysiwigField fieldName="experience" fieldValue={returnerProfile.experience} onSave={saveProfileFieldChange} />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Tell us about your technical skills?</Typography>
                    <WysiwigField fieldName="techSkills" fieldValue={returnerProfile.techSkills} onSave={saveProfileFieldChange} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Transferable Skills</Typography>
                    <WysiwigField fieldName="transferableSkills" fieldValue={returnerProfile.transferableSkills} onSave={saveProfileFieldChange} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Tell us why you are passionate about a career in tech?</Typography>
                    <WysiwigField fieldName="careerInTech" fieldValue={returnerProfile.careerInTech} onSave={saveProfileFieldChange} />
                </Grid>
            </Grid>
        </div>
    );
}

const condition = authUser => !!authUser 

export default withAuthorisation(condition)(ReturnerProfileEdit);