import React from 'react';
import { AuthUserContext, withAuthorisation } from '../Session';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import AppBar from '../AppBar';
import {
    Typography,
    Container,
    Grid
} from '@material-ui/core';


const BusinessSignUpPending = () => {

    return (
        <AuthUserContext.Consumer>
            {authUser => {
                 return ( 
                    <div>
                        <AppBar authUser={authUser} />
                        <div style={{ marginTop: "50px" }}>
                        
                            <Container maxWidth="sm" style={{ paddingTop: "30px" }}>
                                <Typography variant="h5">Account Signup Complete</Typography>
                                <Grid container spacing={3} style={{ padding: "20px 20px" }} >
                                    <Grid item xs={12} style={{textAlign: "center"}}>
                                        <Typography variant="h6">Thank you for choosing Tech Returners - a member of the engagement team will be in touch to arrange your access</Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                        </div>
                    </div>
                 )
                 }
            }
        </AuthUserContext.Consumer>
    );
}

const condition = authUser => !!authUser;

const BusinessSignUpPendingForm = compose(
    withFirebase,
    withAuthorisation(condition),
)(BusinessSignUpPending);



export default BusinessSignUpPendingForm;