import { createMuiTheme } from "@material-ui/core/styles";

const primaryTrPurple =  "#8b249a";
const secondaryTrLilac = "#baa8ba"
const textPrimaryColor = "#545454";

export const overridings = {
  name: 'Tech Returners Theme',
  typography: {
    fontFamily: "Didact Gothic"
  },
  palette: {
    primary: {
      main: primaryTrPurple,
      contrastText: "#fff"
    },
    secondary: {
      main: secondaryTrLilac,
      contrastText: "#fff"
    },
    text: {
      primary: textPrimaryColor
    },
  },
  overrides: {
    MuiTypography: {
      body1: {
        color: textPrimaryColor
      }, 
      h5: {
        color: textPrimaryColor
      },
      h6: {
        color: primaryTrPurple
      },
    }
  }
};

export default createMuiTheme(overridings);