import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import GitHubIcon from '@material-ui/icons/GitHub';
import EmailIcon from '@material-ui/icons/Email';
import DescriptionIcon from '@material-ui/icons/Description';
import Link from '@material-ui/core/Link';
import ReturnerProfileDialog from './returner-profile-dialog';
import ReturnerCVDialog from '../ReturnerProfile/ReturnerCVDialog';
import { withFirebase } from '../Firebase';
import StarsIcon from '@material-ui/icons/Stars';
import Chip from '@material-ui/core/Chip';



const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    margin: "0 auto"
  },
  media: {
    height: 0,
    paddingTop: '65%',
    cursor: "pointer"
  },
}));

function ReturnerProfileCard({returnerProfile, firebase}) {
  const classes = useStyles();
  const [profileDialogOpen, setProfileDialogOpen] = React.useState(false);
  const [cvDialogOpen, setCvDialogOpen] = React.useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState("/yjit/returner_profiles/holding_profile.png");

  useEffect(() => {
      firebase.getStorageDownloadUrl(returnerProfile.profileImageLink).then((url)=> {
        setProfileImageUrl(url);
      });
  });

  const handleOpenClick = () => {
    // Only show expaned profile if the returner has not yet been placed
    if(!returnerProfile.employment) {
      setProfileDialogOpen(true);
    }
  };

  const handleCloseClick = () => {
    setProfileDialogOpen(false);
  };

  let cardHeader = <span>{returnerProfile.profileName}</span>

  if(returnerProfile.employment) {
    cardHeader = <span>
      {returnerProfile.profileName}&nbsp;&nbsp;
        <Chip
          variant="outlined"
          size="small"
          color="primary"
          icon={<StarsIcon />}
          label="Returned to Tech"
        />
      </span>
  }

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={handleOpenClick}>
              <MoreVertIcon />
            </IconButton>
          }
          title={cardHeader}
        />
        {profileImageUrl !== "" &&
          <CardMedia
            className={classes.media}
            image={profileImageUrl}
            title={returnerProfile.profileName}
            onClick={handleOpenClick}
          />
        }
        <CardContent style={{minHeight: 140}}>
          <Typography variant="body2" color="textSecondary" component="p">
            {returnerProfile.profileSummary}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <Link href={returnerProfile.gitHubLink} target="_blank" rel="noreferrer">
              <IconButton aria-label="view github account">
                  <GitHubIcon />
              </IconButton>
          </Link>
          { returnerProfile.cvFileLink && 
                <IconButton aria-label="view C V" onClick={() => {setCvDialogOpen(true)}}>
                    <DescriptionIcon />
                </IconButton>
          }
          {!returnerProfile.employment ?
            <Link href={`mailto:sponsorship@techreturners.com?subject=Returner hiring interest - ${returnerProfile.profileName}`} target="_blank" rel="noreferrer">
                <IconButton aria-label="send email">
                    <EmailIcon />
                </IconButton>
            </Link> :
            <Link href={returnerProfile.employment.url} target="_blank" rel="noreferrer">
                <Typography variant="body2">{returnerProfile.employment.role} with {returnerProfile.employment.name}</Typography>
            </Link>
          }
        </CardActions>
      </Card>
      <ReturnerProfileDialog open={profileDialogOpen} onClose={handleCloseClick} returnerProfile={returnerProfile} />
      {returnerProfile.cvFileLink && 
        <ReturnerCVDialog open={cvDialogOpen} onClose={() => {setCvDialogOpen(false)}} cvLink={returnerProfile.cvFileLink} />
      }
    </>
    
  );
}

export default withFirebase(ReturnerProfileCard)