import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import * as BUSINESS_STATUS from '../../constants/business_status';


const LoginRouteIdentifier = {

    calculateUserLoginDestination(user){

        if(user.userRoles) {
            if(user.userRoles.includes(ROLES.BUSINESS_ROLE)) {
                // Business user here to look to hire
                if( user.businessInfo && 
                    user.businessInfo.termsAgreed === true && 
                    user.businessInfo.verificationStatus === BUSINESS_STATUS.VERIFICATION_STATUS_APPROVED) {

                    return ROUTES.ENGAGEMENT_RETURNER_PROFILES;
                }
                else if(
                    user.businessInfo && 
                    user.businessInfo.termsAgreed === true && 
                    user.businessInfo.verificationStatus === BUSINESS_STATUS.VERIFICATION_STATUS_PENDING
                ) {
                    return ROUTES.ENGAGEMENT_BUSINESS_VERIFICATION_PENDING;
                }
                else {
                    return ROUTES.ENGAGEMENT_BUSINESS_TERMS;
                }
            }
            else if(user.userRoles.includes(ROLES.TR_EMPLOYEE) || user.userRoles.includes(ROLES.TR_EMPLOYEE_ADMIN)) {
                // Tech Returners employee here to administrate
                return ROUTES.ENGAGEMENT_RETURNER_PROFILES;
            }
            else if(user.userRoles.includes(ROLES.RETURNER)) {
                // Assume they are a returner
                return ROUTES.RETURNER_PROFILE;
            }
        }

        return ROUTES.STATUS_404;
    }


}

export default LoginRouteIdentifier;